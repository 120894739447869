import DynamicPage from "../../structure/DynamicPage/DynamicPage";
import { pages } from "@ecp-redux/api/pages";
import React from "react";
import { transformPortalPage } from "@ecp-redux/dto/page/transformPortalPage";
import { usePageInPageContext } from "./PageInPageContext";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import { useTranslation } from "react-i18next";
import { isPortalSide } from "@ecp-boxes/helpers/helpers";

interface PageInPageProps {}

const PageInPage: React.FC<PageInPageProps> = ({}) => {
  const pageInPage = usePageInPageContext();
  const isMobile = useIsMobilePortal();
  const { t } = useTranslation();

  const { data, isError } = pages.useGetPagePortalQuery(
    {
      pageQuery: `url=${pageInPage?.url}`,
      platform: isMobile ? "MOBILE" : "DESKTOP",
    },
    { skip: pageInPage === null }
  );

  if (!data || isError) {
    return isPortalSide() ? null : <div>{`${t("global_keys.empty_box")}`}</div>;
  }

  const d = transformPortalPage(data);

  return <DynamicPage data={d} activePathExtraSettings="" />;
};

export default PageInPage;
