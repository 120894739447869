import { TColorReadoutValue } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { API, api } from "./api";

export enum MediaAssetsType {
  NONE = "NONE",
  COLOR = "COLOR",
  IMAGE = "IMAGE",
}

export interface IMediaAsset {
  mediaAssetsType: MediaAssetsType;
  color?: TColorReadoutValue | null;
  image?: {
    defaultIconUrl: string;
    animatedGifUrl?: string;
    activeIconUrl?: string;
    disabledIconUrl?: string;
  } | null;
}

export interface IAttributeMediaAsset {
  title: IMediaAsset;
  values: {
    [key: string]: IMediaAsset;
  } | null;
}
export interface IAttributesWithMediaAssets {
  [key: string]: IAttributeMediaAsset;
}

interface IPostMediaAssetsPayload {
  [category: string]: {
    title: {
      mediaAssetsType: MediaAssetsType;
      color?: TColorReadoutValue | null;
      image?: {
        defaultIconUrl: string;
        animatedGifUrl?: string;
        activeIconUrl?: string;
        disabledIconUrl?: string;
      } | null;
    };
    values: Record<
      string,
      {
        mediaAssetsType: MediaAssetsType;
        color?: TColorReadoutValue | null;
        image?: {
          defaultIconUrl: string;
          animatedGifUrl?: string;
          activeIconUrl?: string;
          disabledIconUrl?: string;
        } | null;
      }
    > | null;
  };
}

export const mediaAssets = api
  .enhanceEndpoints({
    addTagTypes: ["MEDIA_ASSETS"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMediaAssets: builder.query<IAttributesWithMediaAssets, string[] | []>({
        query: (body) => ({
          url: API.getMediaAssets.endpoint(),
          method: "POST",
          body,
        }),
        providesTags: ["MEDIA_ASSETS"],
      }),
      postMediaAssets: builder.mutation<void, IPostMediaAssetsPayload>({
        query: (body) => {
          return {
            url: API.postMediaAssets.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["MEDIA_ASSETS"],
      }),
      deleteMediaAssets: builder.mutation<void, string[]>({
        query: (body) => {
          return {
            url: API.deleteMediaAssets.endpoint(),
            method: "DELETE",
            body,
          };
        },
        invalidatesTags: ["MEDIA_ASSETS"],
      }),
    }),
  });

export const {
  useGetMediaAssetsQuery,
  usePostMediaAssetsMutation,
  useDeleteMediaAssetsMutation,
} = mediaAssets;

export const { getMediaAssets } = mediaAssets.endpoints;
