import { IMediaAssetsSettings } from "@ecp-boxes/boxes/BoxSearchResults/BoxSearchResults.types";
import { IProductAttributeValue } from "./ProductModule.types";
import AttributeWithMediaAsset from "./AttributeWithMediaAsset";
import { ReactNode } from "react";
import { IMediaAsset } from "@ecp-redux/api/mediaAssets";

interface IProductAttributeValueProps {
  assetSettings: IMediaAssetsSettings | undefined;
  productAttributeValue: IProductAttributeValue | IProductAttributeValue[];
  mediaAssetsForValues: { [key: string]: IMediaAsset } | null | undefined;
  isValueWithAsset: boolean;
  children?: ReactNode;
}

const ProductAttributeValue: React.FC<IProductAttributeValueProps> = ({
  assetSettings,
  productAttributeValue,
  mediaAssetsForValues,
  isValueWithAsset,
  children,
}) => {
  if (productAttributeValue === undefined) return null;

  if (!isValueWithAsset) {
    return children;
  }

  if (Array.isArray(productAttributeValue)) {
    return (
      <div>
        {productAttributeValue.map((attributeValue, index) => (
          <AttributeWithMediaAsset
            key={index + attributeValue.value}
            assetSettings={assetSettings}
            mediaAsset={mediaAssetsForValues?.[attributeValue.value]}
          >
            {children}
          </AttributeWithMediaAsset>
        ))}
      </div>
    );
  }

  return (
    <AttributeWithMediaAsset
      assetSettings={assetSettings}
      mediaAsset={mediaAssetsForValues?.[productAttributeValue.value]}
    >
      {children}
    </AttributeWithMediaAsset>
  );
};

export default ProductAttributeValue;
