import React from "react";
import PageInPage from "./PageInPage";
import {
  PageInPageProvider,
  PageInPageProviderProps,
} from "./PageInPageContext";

const PageInPageComponent: React.FC<PageInPageProviderProps> = (props) => {
  return (
    <PageInPageProvider {...props}>
      <PageInPage />
    </PageInPageProvider>
  );
};

export default PageInPageComponent;
