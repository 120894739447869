import { IAttributesWithMediaAssets } from "@ecp-redux/api/mediaAssets";
import { TAttributeValue } from "@ecp-redux/dto/searchResults.types";

export const labelsToString = (
  o: TAttributeValue | TAttributeValue[] | undefined
) => {
  return Array.isArray(o) ? o.map((a) => a.label).join(", ") : o?.label;
};

export const valuesToString = (
  o: TAttributeValue | TAttributeValue[] | undefined
) => {
  return Array.isArray(o) ? o.map((a) => a.value).join(", ") : o?.value;
};

export const getValue = (productAttributes: any) => {
  if (Array.isArray(productAttributes)) {
    return productAttributes
      .map((el) => {
        if (typeof el.value === "object" && el.value !== null) {
          return Object.values(el.value).join(" ");
        }
        return el.label ?? el.value;
      })
      .join(", ");
  }
  if (typeof productAttributes === "object") {
    return productAttributes.label ?? productAttributes.value;
  }
  return productAttributes;
};

export const hasMediaAssets = (
  attribute: string,
  response: IAttributesWithMediaAssets | undefined
): boolean => {
  if (response) {
    return Object.keys(response).includes(attribute);
  }
  return false;
};
